<template lang="pug">
div
  slot(name="activator" :toggle="toggle")
    v-btn(
      small
      color="primary"
      @click="openMijnCBRModal"
      :disabled="disabledAction"
      outlined
      data-test="c-set-mijn"
    ) Set MIJN Exam
  v-dialog(v-model="showing" max-width=600 scrollable)
    courseChooseProvider(:item="formData" @input:course="updateCourse")
      template(v-slot:default="courseChoose")
        v-card(data-test="c-set-modal")
          v-card-title Set MIJN Exam
          v-card-text
            filter-autocomplete(
              :value="formData.course.city"
              @input="courseChoose.loadCoursesDatesByLocation"
              :getItems="getCourseLocation"
              label="Course Location"
              item-value="ID"
              item-text="name"
              test-name="c-set-location-course"
              :error="formErrors.hasError('courseLocation')"
              :error-messages="formErrors.fields.courseLocation"
            )
            v-autocomplete(
              :value="formData.course.ID"
              @input="setCourseHandler($event, courseChoose.courses)"
              label="Course"
              :loading="courseChoose.loading"
              :disabled="!courseChoose.courses.length"
              :items="courseChoose.courses"
              data-test="c-set-date-course"
              item-text="dateAndName"
              item-value="ID"
              return-object
              :error="formErrors.hasError('courseDate')"
              :error-messages="formErrors.fields.courseDate"
            )
            fieldDatepicker(
              v-model="formData.examDate"
              label="Exam date"
              data-test="c-set-date"
              :readonly="disabled"
              :min="currentDate"
              :error="formErrors.hasError('examDate')"
              :error-messages="formErrors.getError('examDate')"
            )
            v-combobox(
              :value="formData.examTime"
              @update:search-input="updateVal($event, 'examTime')"
              @change="updateVal($event, 'examTime')"
              label="Exam time"
              data-test="c-set-time"
              :items="examTimeItems"
              :error="formErrors.hasError('examTime')"
              :error-messages="formErrors.fields.examTime"
              timepicker
            )
            filter-autocomplete(
              v-model="formData.location"
              :getItems="getExamLocation"
              label="Exam location"
              test-name="c-set-location"
              :error="formErrors.hasError('location')"
              :error-messages="formErrors.fields.location"
            )
            filter-autocomplete(
              :getItems="getProductNames"
              v-model="formData.productName"
              item-text="name"
              item-value="name"
              label="Product name"
              test-name="c-set-product"
              :error="formErrors.hasError('productName')"
              :error-messages="formErrors.fields.productName"
              @itemsLoaded="setDefaultProduct"
            )
            v-text-field(
              v-model="formData.reservationCode"
              label="Reservation code"
              type="number"
              data-test="c-set-code"
              :error="formErrors.hasError('reservationCode')"
              :error-messages="formErrors.fields.reservationCode"
            )
            div.text-center.mt-3
          v-card-actions
            v-spacer
            v-btn(color="error" text @click="toggle" data-test="c-set-close") Close
            v-btn(color="success" text @click="submit" data-test="c-set-submit") Apply
</template>

<script>
import showingMixin from "@/mixins/showing.mixin";
import errorsMixin from '@/mixins/errors.mixin'
import FormErrors from "@/util/form-errors"
import { mapActions } from 'vuex'
import { REQUIRED, DATE_INDIAN } from '@/util/validation-rules'
import { SET_MIJN_ARGS } from '../core/candidates-const'
import { convertToDefaultDateFormat, convertToDefaultBackendFormat } from '@/util'
import CourseModel from '../../../models/courseModel'
import { PRODUCT } from '@/util/const'

export default {
  mixins: [showingMixin, errorsMixin],

  props: {
    disabled: Boolean,
    ID: Number,
    defaultCourse: Object,
    status: String,
    form: Object
  },

  data: () => ({
    formData: {
      location: null,
      examDate: null,
      examTime: null,
      productName: null,
      course: new CourseModel(),
      reservationCode: null,
    },
    currentDate: convertToDefaultBackendFormat(new Date(Date.now())),
    examTimeItems: ['13:00', '13:20', '13:40', '14:00', '14:20', '14:40', '15:00', '15:20', '15:40'],
    formErrors: new FormErrors(),
    apiAttrs: {
      [SET_MIJN_ARGS.LOCATION]: 'location',
      [SET_MIJN_ARGS.DATETIME]: 'examDate',
      [SET_MIJN_ARGS.PRODUCT_NAME]: 'productName',
      reservation_code: 'reservationCode'
    }
  }),

  computed: {
    disabledAction() {
      return this.disabled;
    }
  },

  inject: ['svc'],

  methods: {
    ...mapActions({
      getExamLocation: 'crmExamLocation/list',
      getCourseLocation: 'crmCourseCities/list',
      getProductNames: 'crmProductNames/list'
    }),

    Svc() {
      return this.svc()
    },

    submit() {
      if(!this.validate(this.formErrors)) {
        this.$notify({text: 'Invalid fields', type: 'error'})
        return
      }
      this.send()
    },

    validate(validator) {
      validator.resetFields()
      this.validateRequired(this.formData.examDate, 'examDate', validator)
      this.validateTime(this.formData.examTime, 'examTime', validator)
      this.validateRequired(this.formData.location, 'location', validator)
      this.validateRequired(this.formData.productName, 'productName', validator)
      this.validateCourse(this.formData.course, validator)
      return validator.isValid()
    },

    validateDateTime(val, validator) {
      let match = DATE_INDIAN(val)
      if(match === true) return true
      validator.invalidField('examDate', match)
      return false
    },

    validateTime(val, field, validator) {
      if(/^([01]?[0-9]|2[0-3]):[0-5][0-9]$/.test(val)) return true
      validator.invalidField(field, 'Not correct time')
    },

    validateRequired(val, field, validator) {
      let match = REQUIRED(val)
      if(match === true) return true
      validator.invalidField(field, match)
      return false
    },

    validateCourse(course, validator) {
      if (!course.ID) validator.invalidField('courseDate', 'field required')
      if (!course.city) validator.invalidField('courseLocation', 'field required')
    },

    updateVal(val, field) {
      this.formData[field] = val
    },

    async send() {
      try {
        await this.svc().setMijnExam(this.ID, this.formData)
        this.$notify({text: 'Set MIJN Exam started', type: 'success'})
        this.toggle()
      } catch (error) {
        console.log(error)
        this.processErrorWithForm(error, {
          redirectName: this.$ROUTER_NAMES.LOGIN_CRM,
          formErrors: this.formErrors,
          apiAttributes: this.apiAttrs
        });
      }
    },

    reset() {
      this.formData = {
        location: null,
        examDate: null,
        examTime: null,
        productName: null,
        course: new CourseModel(),
        reservationCode: null
      }
    },

    applyDefaultValues() {
      if (this.defaultCourse && this.defaultCourse.city) this.formData.course = this.defaultCourse
    },

    updateCourse(course) {
      this.formData.course = course
    },

    setCourseHandler(course, courses) {
      this.formData.course = course
      if (!this.formData.examDate && !this.formData.location) this.autocompleteExam(course, courses)
    },

    autocompleteExam(course, courses) {
      let selectedCourse = courses.find(_course => _course.ID === course.ID)
      if(!selectedCourse) return
      if(!selectedCourse.exams.length) return

      let examDate = convertToDefaultDateFormat(selectedCourse.exams[0].date)
      if (selectedCourse.exams[0].dateFrom && selectedCourse.exams[0].dateTo) examDate = convertToDefaultDateFormat(selectedCourse.exams[0].dateFrom)

      this.formData.examDate = examDate
      this.formData.location = selectedCourse.exams[0].location
    },

    setDefaultProduct() {
      this.formData.productName = PRODUCT.BTH
    },

    setDefaultData() {
      if (!this.formData.productName) this.setDefaultProduct()
      if (!this.formData.location) this.formData.location = this.form.location
      if (!this.formData.examDate) this.formData.examDate = this.form.date
      if (!this.formData.examTime) this.formData.examTime = this.form.time
    },

    openMijnCBRModal() {
      this.updateShowing(true)
      this.setDefaultData();
    }
  },

  watch: {
    showing(isShowing) {
      if(!isShowing) {
        this.reset()
        return
      }
    }
  },

  components: {
    filterAutocomplete: () => import('@/components/global/FilterAutocomplete.vue'),
    fieldDatepicker: () => import('@/components/global/FieldDatepicker.vue'),
    CourseChooseProvider: () => import('./reservation/CandidateCourseChooseProvider.vue')
  }
}
</script>
